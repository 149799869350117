import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'

import Navigation from '../components/navigation'
import Footer from '../components/footer'
import Dialog from '../components/dialog'
import DemoForm from '../components/demoForm'
// import { ReactSVG } from 'react-svg';
// import Video from '../assets/cubic1.mp4'
// import GroupPeople from '../assets/home/group-people.png'

// import { ReactComponent as FireImgComp } from '../assets/home/blazeguard-ai.svg'
// import { ReactComponent as InspectraXComp } from '../assets/home/inspectra-x.svg'
// import { ReactComponent as CopilotImgComp } from '../assets/home/co-pilot.svg'
import { ReactComponent as SafeIcon } from '../assets/safeIcon.svg'
import { ReactComponent as SeverityImage } from '../assets/severityTextIcon.svg'
import { ReactComponent as RightArrow } from '../assets/right-arrow-icon.svg'

import { ReactComponent as ComingSoon } from '../assets/coming-soon-badge.svg'
import { ReactComponent as New } from '../assets/new-badge.svg'

// import { ReactComponent as GENAI } from '../assets/home/home-genAI-bar-2.svg'
// import { ReactComponent as MachineVision } from '../assets/home/home-mv-bar-2.svg'
// import { ReactComponent as EarthScience } from '../assets/home/home-ES-bar-2.svg'
// import { ReactComponent as CustomSolution } from '../assets/home/home-CS-bar.svg'

// import { ReactComponent as HowItWorksT } from '../assets/home/how-it-works-title-2.svg'
// import { ReactComponent as HowItWorksD } from '../assets/home/how-it-works-data.svg'
// import { ReactComponent as HowItWorksM } from '../assets/home/custom-solution-M.svg'

import { ReactComponent as CloudInHouse } from '../assets/home/cloud-inhouse-ai.svg'
import { ReactComponent as Scalability } from '../assets/home/scalability.svg'
import { ReactComponent as IntuitiveCard } from '../assets/home/highly-intuitive.svg'
import CustomSolutionD from '../assets/home/CustomSolutionD.png'

const productData = [
    {
        name: 'fire',
        imageComp: <img
            src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/blazeguard-ai.svg"
            alt="BlazeGuard AI"
        />,
        title: 'Proenv.ai- Advanced Environmental AI',
        description: 'Leveraging Cameras, Edge processors & AI-powered image analysis, Our product offers un-paralleled accuracy in identifying nature co existence',
        hoverIcon: <SeverityImage />,
        hoverTxt: 'Allocated to Madhu',
        link: '/product/proenvAI',
        comingSoon: false,
        newProduct: false,
    },
    {
        name: 'elephant',
        imageComp: <img
            src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/inspectra-x.svg"
            alt="BlazeGuard AI"
        />,
        title: 'InspectraX - Visual defect identification',
        description: 'Reimagine quality control at every step as your gateway to high quality products with very low defects, where most defects are caught instantly early in the production cycle.',
        hoverIcon: <SeverityImage />,
        hoverTxt: 'Elephant found',
        link: '/product/inspectra-x',
        comingSoon: false,
        newProduct: false,
    },
    {
        name: 'copilot',
        imageComp: <img
            src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/co-pilot.svg"
            alt="BlazeGuard AI"
        />,
        title: 'Vidatt Copilot ',
        description: 'Experience cutting-edge solutions engineered to leverage data for optimal business performance with GenAi.',
        hoverIcon: <SafeIcon />,
        hoverTxt: 'Safe from floods',
        link: '/product/co-pilot',
        comingSoon: false,
        newProduct: true,
    },
]

const scopesData = [
    {
        name: 'ai',
        imageComp: <CloudInHouse />,
        title: 'Cloud, In-House, & Full-Stack Solutions',
        description: 'Elevate your business with scalable cloud solutions, efficient deployment, and customized full-stack systems to meet all operational needs securely.',
    },
    {
        name: 'accurate',
        imageComp: <Scalability />,
        title: 'Scalable + Accurate + Versatile',
        description: 'We use latest advancements in machine learning with advanced deep learning models, leading to a remarkable system-level accuracy',
    },
    {
        name: 'intuitive',
        imageComp: <IntuitiveCard />,
        title: 'We make Highly Intuitive & Customizable User Interfaces',
        description: 'Single-Pane-Of-Glass Graphical Dashboarding, Real-Time Alerting, Post Processing/ Actioning & User-Customizable Views.',
    }
]

const Home = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const lastHash = useRef('')
    const [showDialog, setShowDialog] = useState(false)
    const [mainNav, setMainNav] = useState(0)
    const prevMainNav = useRef(0)
    const [isMobile, setMobile] = useState(false)

    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1)
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                document
                    .getElementById(lastHash.current)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                lastHash.current = ''
            }, 100)
        } else {
            window.scrollTo(0, 0)
        }
    }, [location])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (mainNav === 3) {
                setMainNav(0);
            } else {
                setMainNav(mainNav + 1)
            }
        }, 10000);

        return () => clearInterval(intervalId)
    }, [mainNav])

    useEffect(() => {
        if (mainNav !== prevMainNav.current) {
            // Perform any necessary actions or updates here
            prevMainNav.current = mainNav
        }
    }, [mainNav])
    const textRef = useRef(null);
    const containerRef = useRef(null);
    const [activeWordIndex, setActiveWordIndex] = useState(0);
    const [scrollLock, setScrollLock] = useState(true);

    const words = [
        "Explore",
        "a",
        "path",
        "of",
        "cutting-edge",
        "innovation",
        "alongside",
        "Vidatt.",
        "Our",
        "commitment",
        "to",
        "engineer",
        "top-notch",
        "highly",
        "accurate",
        "AI",
        "solutions",
        "is",
        "paving",
        "the",
        "way",
        "for",
        "meaningful",
        "change.",
        "Experience",
        "pioneering",
        "efforts",
        "in",
        "data",
        "center",
        "mediation", "/",
        "provisioning."
    ];
    // const words2 = [
    //     "Experience",
    //     "pioneering",
    //     "efforts",
    //     "in",
    //     "data",
    //     "center",
    //     "mediation/",
    //     "provisioning"

    // ];
    useEffect(() => {
        const handleScroll = (e) => {
            if (!containerRef.current || !scrollLock) return;

            const container = containerRef.current;
            const rect = container.getBoundingClientRect();

            if (rect.top < window.innerHeight / 2 && rect.bottom > window.innerHeight / 2) {
                e.preventDefault();

                // Calculate scroll progress
                const totalHeight = rect.height;
                const scrollOffset = window.innerHeight / 2 - rect.top;
                const progress = Math.min(Math.max(scrollOffset / totalHeight, 0), 1); // Clamp progress between 0 and 1

                // Update active word index based on progress
                const newIndex = Math.floor(progress * words.length);

                setActiveWordIndex(newIndex);

                // Unlock scrolling when animation completes
                if (progress === 1) {
                    setScrollLock(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll, { passive: false });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    })

    const handleResize = () => {
        if (window.innerWidth <= 600) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        const { innerWidth } = window
        if (innerWidth <= 600) {
            setMobile(true)
        }
    }, [setMobile])

    // scope carousal
    const [activeIndex, setActiveIndex] = useState(0);
    const carouselRef = useRef(null);

    // Touch handling for swiping
    const [startX, setStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setIsDragging(true);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;

        const diff = e.touches[0].clientX - startX;
        if (diff > 50 && activeIndex > 0) {
            // Swipe right
            setActiveIndex((prev) => prev - 1);
            setIsDragging(false);
        } else if (diff < -50 && activeIndex < scopesData.length - 1) {
            // Swipe left
            setActiveIndex((prev) => prev + 1);
            setIsDragging(false);
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };


    // product carousal
    const carouselProductsRef = useRef(null);
    const [currentProductIndex, setCurrentProductIndex] = useState(0);
    // Touch handling for swipe gestures 
    // const [startX, setStartX] = useState(0);
    const [isSwiping, setIsSwiping] = useState(false);

    const handleSwipeStart = (e) => {
        setStartX(e.touches[0].clientX);
        setIsSwiping(true);
    };

    const handleSwipeMove = (e) => {
        if (!isSwiping) return;

        const swipeDistance = e.touches[0].clientX - startX;
        if (swipeDistance > 50 && currentProductIndex > 0) {
            // Swipe right
            setCurrentProductIndex((prev) => prev - 1);
            setIsSwiping(false);
        } else if (swipeDistance < -50 && currentProductIndex < productData.length - 1) {
            // Swipe left
            setCurrentProductIndex((prev) => prev + 1);
            setIsSwiping(false);
        }
    };

    const handleSwipeEnd = () => {
        setIsSwiping(false);
    };
    // const [currentIndex, setCurrentIndex] = useState(0);

    // const handleNext = () => {
    //     if (currentIndex < productData.length - 1) {
    //         setCurrentIndex(currentIndex + 1);
    //     }
    // };

    // const handlePrevious = () => {
    //     if (currentIndex > 0) {
    //         setCurrentIndex(currentIndex - 1);
    //     }
    // };

    return (
        <div className='w-full h-full'>
            <Navigation />
            <div className="relative bg-[#000000] flex flex-col justify-center items-center text-center pb-20">
                {/* Background Video */}
                {/* <video
                    className="absolute inset-0 w-[50%] 2xl:w-[40%] h-[90%] my-[5%] ml-[25%] 2xl:ml-[30%] 2xl:my-[2%] object-cover opacity-[0.15] pointer-events-none"
                    src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/cubic1.mp4"
                    autoPlay
                    loop
                    muted
                /> */}
                <img
                    className="absolute inset-0 w-[50%] 2xl:w-[40%] h-[90%] my-[5%] ml-[25%] 2xl:ml-[30%] 2xl:my-[2%] object-cover opacity-[0.15] pointer-events-none"
                    src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/cubic.gif" alt="vidatt home"
                    autoPlay
                    loop
                    muted
                />
                <div className="flex flex-row items-center gap-2 mt-32 mb-10 mx-2 text-[10px] leading-[11.9px] md:text-xs md:leading-[17px] text-[#C9C9C9] font-bold rounded-full bg-[#55556D]">
                    <div className={`p-px cursor-pointer ${mainNav === 0 ? 'rounded-full bg-gradient-to-r from-[#FFFFFF] to-[#8D72FA] text-white font-bold' : ''}`}>
                        <div className={`p-2 rounded-full ${mainNav === 0 ? 'bg-[#121217] font-bold' : 'bg-[#55556D]'}`} onClick={() => { setMainNav(0); }}>Gen AI</div>
                    </div>
                    <div className={`p-px cursor-pointer ${mainNav === 1 ? 'rounded-full bg-gradient-to-r from-[#FFFFFF] to-[#8D72FA] text-white font-bold' : ''}`}>
                        <div className={`p-2 rounded-full ${mainNav === 1 ? 'bg-[#121217] font-bold' : 'bg-[#55556D]'}`} onClick={() => { setMainNav(1); }}>Machine Vision</div>
                    </div>
                    <div className={`p-px cursor-pointer ${mainNav === 2 ? 'rounded-full bg-gradient-to-r from-[#FFFFFF] to-[#8D72FA] text-white font-bold' : ''}`}>
                        <div className={`p-2 rounded-full ${mainNav === 2 ? 'bg-[#121217] font-bold' : 'bg-[#55556D]'}`} onClick={() => { setMainNav(2); }}>Environmental AI</div>
                    </div>
                    <div className={`p-px cursor-pointer ${mainNav === 3 ? 'rounded-full bg-gradient-to-r from-[#FFFFFF] to-[#8D72FA] text-white font-bold' : ''}`}>
                        <div className={`p-2 rounded-full ${mainNav === 3 ? 'bg-[#121217] font-bold' : 'bg-[#55556D]'}`} onClick={() => { setMainNav(3); }}>Custom Solutions</div>
                    </div>
                </div>

                {!isMobile ?
                    <div>
                        {mainNav === 0 ? <img src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/home-genAI-bar-2.svg" className='w-[98%] h-[98%] mx-auto' alt="vidatt home" /> : mainNav === 1 ? <img src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/home-mv-bar-2.svg" className='w-[98%] h-[88%] mx-auto' alt="vidatt home" /> : mainNav === 2 ? <img src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/home-ES-bar-2.svg" className='w-[98%] h-[98%] mx-auto' alt="vidatt home" /> : <img src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/home-CS-bar.svg" className='w-[98%] h-[98%] mx-auto' alt="vidatt home" />}
                    </div> :
                    <div>
                        {mainNav === 0 ?
                            <div className='w-[90%] mx-auto text-center'>
                                <div className='font-bold text-[#FFFFFF] text-[37px] leading-[42px]'>Welcome to Vidatt Copilot Our Gen AI that is Built Ground up for SaaS</div>
                                <div className='font-normal text-[#90A3BF] text-[19px] leading-[28px] mt-2'>Retrieve your data faster than ever before to make informed & precise decisions in a timely manner</div>
                            </div> : mainNav === 1 ?
                                <div className='w-[90%] mx-auto text-center'>
                                    <div className='font-bold text-[#FFFFFF] text-[37px] leading-[42px]'>Simplifying Intricate Industrial Visual Inspections with AI.</div>
                                    <div className='font-normal text-[#90A3BF] text-[19px] leading-[28px] mt-2'>Revolutionize the way we interact with our planet through groundbreaking Earth Sciences & State - of- the - art Vision Services</div>
                                </div> : mainNav === 2 ?
                                    <div className='w-[90%] mx-auto text-center'>
                                        <div className='font-bold text-[#FFFFFF] text-[37px] leading-[42px]'>Industry’s best AI powered Environmental AI Software</div>
                                        <div className='font-normal text-[#90A3BF] text-[19px] leading-[28px] mt-2'>Enhancing prefire detection and mitigating human-animal conflict by utilizing advanced elephant detection technology and earth sciences.</div>
                                    </div> :
                                    <div className='w-[90%] mx-auto text-center'>
                                        <div className='font-bold text-[#FFFFFF] text-[37px] leading-[42px]'>All-in-One Digital Partner – From Concept to Completion</div>
                                        <div className='font-normal text-[#90A3BF] text-[19px] leading-[28px] mt-2'>Our expertise spans over various industries in Research & Discovery, Creative Product Design, Advanced Development, and Meticulous Testing—ensuring that every phase of your project is covered with care and precision.</div>
                                    </div>
                        }
                    </div>
                }

                {/* Action Buttons */}
                <div className="flex flex-col md:flex-row gap-4 justify-center items-center my-8">
                    <div
                        className="min-w-[145px] px-5 py-2 text-white text-base font-sans font-semibold bg-[#7C5CFC] rounded-full cursor-pointer"
                        onClick={() => { setShowDialog(true); }}
                    >
                        Request for Demo
                    </div>
                    <div
                        className="min-w-[125px] px-5 py-2 text-[#C3D4E9] text-base font-sans font-semibold bg-[#282833] rounded-full cursor-pointer"
                        onClick={() => {
                            navigate(mainNav === 0 ? '/product/co-pilot' : mainNav === 1 ? '/product/inspectra-x' : mainNav === 2 ? '/product/proenvAI' : '/contact-us');
                        }}
                    >
                        {mainNav === 0 ? 'About Vidatt Copilot' : mainNav === 1 ? 'About InspectraX' : mainNav === 2 ? 'About Proenv.ai' : 'Contact Us'}
                    </div>
                </div>
            </div>
            {/* <ProductTile /> */}
            <div className='flex flex-col gap-8 justify-center items-center'>
                <div
                    ref={containerRef}
                    className="flex flex-col justify-center items-center py-10 h-screen "
                >
                    <div
                        ref={textRef}
                        className="w-[90%] xl:w-[90%]  tracking-tight text-4xl md:text-5xl md:leading-[96px] font-bold text-center text-[#7047EB] mx-auto "
                    >
                        {words.map((word, idx) => (
                            <span
                                key={idx}
                                className={`transition-colors duration-300 ${idx <= activeWordIndex ? "text-[#7047EB] font-bold" : "text-[#A9A9BC] "
                                    }`}
                            >
                                {word}{" "}
                            </span>
                        ))}
                    </div>
                </div>
                <div className='w-[94%] xl:w-[90%] mx-auto flex flex-col gap-4'>
                    <div className='text-left text-purpleBtn font-bold text-xl'>PRODUCTS</div>
                    <div className='flex flex-col md:flex-row gap-4 justify-between items-start'>
                        {!isMobile && <div className='w-[90%] md:w-[50%] text-left text-black text-2xl lg:text-[40px] lg:leading-[60px] font-bold'>Revolutionising Automation Leveraging Artificial Intelligence</div>}
                        {isMobile && <div className='w-[90%] md:w-[50%] text-left text-black text-2xl font-bold'>Revolutionising sustainability & Earthsciences.</div>}
                        <div className='w-full text-left text-[#596780] text-xl'>Harnessing the potential of data, we excel in crafting cutting-edge AI solutions to deliver impactful results.</div>
                    </div>
                </div>
                <div className='w-0 h-0 md:w-full md:h-full xl:w-[90%] xl:mx-[5%] md:my-6 md:px-4 flex flex-col justify-center md:flex-row md:justify-start md:gap-4 overflow-auto'>
                    {
                        productData.map((item) => (
                            <div className='w-full h-full flex flex-col justify-between relative md:gap-2' key={item.name} style={{ minHeight: '610px' }}>
                                <div className='w-[90%] min-w-[350px] 2xl:w-full'>{item.imageComp}</div>
                                <div className="max-w-screen text-2xl flex flex-row items-center gap-4 font-bold text-left">
                                    <div>{item.title}</div>
                                    {item.comingSoon && <ComingSoon className='inline-block' />}
                                    {item.newProduct && <New />}
                                </div>
                                <div className='max-w-[350px] xl:max-w text-base text-[#596780] text-left flex-grow'>{item.description}</div>
                                <div
                                    className={`mt-2 text-base font-semibold text-left text-purpleBtn flex flex-row gap-1 items-center ${item.comingSoon ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                    onClick={() => navigate(item.link)}
                                >
                                    Know more<RightArrow className='w-4 h-3' />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div
                    className="w-full max-w-screen relative overflow-hidden md:hidden"
                    ref={carouselProductsRef}
                    onTouchStart={handleSwipeStart}
                    onTouchMove={handleSwipeMove}
                    onTouchEnd={handleSwipeEnd}
                >
                    <div
                        className="flex flex-row pl-4 gap-12 ml-10 transition-transform duration-500"
                        style={{
                            transform: `translateX(-${currentProductIndex * 80}%)`,
                        }}
                    >
                        {
                            productData.map((item) => {
                                return (
                                    <div className='min-w-screen h-full flex flex-col md:gap-2 ' key={item.name}>
                                        <div className='w-[90%] min-w-[250px]'>{item.imageComp}</div>
                                        <div className='max-w-screen text-2xl flex flex-row items-center gap-4 font-bold text-left'>
                                            <div>{item.title}</div>
                                            {item.comingSoon && <ComingSoon className='inline-block' />} {item.newProduct && <New />}
                                        </div>
                                        <div className='max-w-[350px] xl:max-w text-base text-[#596780] text-left'>{item.description}</div>
                                        <div className={`mt-2 text-base font-semibold text-left text-purpleBtn flex flex-row gap-1 items-center ${item.comingSoon ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} onClick={() => { navigate(item.link) }}>Know more<RightArrow className='w-4 h-3' /></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/* product dots */}
                <div className="mt-4 flex gap-2 md:hidden justify-center items-center">
                    {productData.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentProductIndex(index)}
                            className={`rounded-full transition-all duration-300 ${currentProductIndex === index
                                ? "w-3 h-3 bg-[#666CE4] border-[#666CE4]" // Active dot: larger and blue
                                : "w-2 h-2 bg-[#83838a] border-gray-400" // Inactive dot: smaller and white
                                }`}
                        ></button>
                    ))}
                </div>
                <div className='w-full bg-homeBg flex flex-col items-center relative py-4'>
                    {/* {!isMobile && <div className='w-0 h-0 md:w-full md:h-full text-purpleDark text-xl text-center mt-4 md:mt-20 uppercase'>Custom solutions</div>} */}
                    {/* <img src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/how-it-works-title-2.svg" className='text-center w-0 h-0 md:w-[94%] md:h-full xl:h-[80%] xl:w-[80%] mx-auto' /> */}
                    <img src={CustomSolutionD} className='text-center w-0 h-0 md:w-[94%] md:h-full xl:h-[80%] xl:w-[80%] mx-auto pt-4' alt="vidatt home" />
                    <img src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/custom-solution-M_IMGCentury.jpg" className='w-[88%] h-full md:w-0 md:h-0' alt="vidatt home" />
                </div>
                <div className='w-0 md:w-full md:py-10 bg-homeBg flex flex-col overflow-auto md:-mt-10'>
                    <img src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/how-it-works-data.svg" className='w-0 h-0 md:w-full md:h-full md:min-w-[2040px] md:mx-[6%]' alt="vidatt home" />
                </div>
                <div className='w-[90%] md:w-full md:h-[750px] bg-white flex flex-col gap-4 items-center md:px-10 xl:px-20 py-2 md:py-10'>
                    <div className='text-purpleBtn uppercase font-bold text-xl'>ABout Us</div>
                    <div className='font-bold text-2xl md:text-[24px] md:leading-[36px] tracking-[-3%] mb-4'>We are engineering focused & solution oriented team, making products scale ready & market fit</div>
                    <div className='flex md:gap-2 w-0 h-0 md:w-full md:h-full overflow-x-scroll  '>
                        {
                            scopesData.map((item, index) => {
                                return (
                                    // <ProductTile key={item.name} product={item} />
                                    <div key={index} className={`w-[90%] md:w-full ml-2 ${index === 1 ? '-mt-8 md:-ml-6 xl:-ml-2' : ''}`}>{item.imageComp}</div>
                                )
                            })
                        }
                    </div>
                    <div
                        className="w-full max-w-md relative overflow-hidden md:hidden"
                        ref={carouselRef}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <div
                            className="flex flex-row pl-4 space-x-3 md:space-x-0 transition-transform duration-500"
                            style={{
                                transform: `translateX(-${activeIndex * 100}%)`,
                            }}
                        >
                            {scopesData.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`w-[90%] max-w-screen ${index === 1 ? '-mt-8' : ''}`}
                                    >
                                        {item.imageComp}
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                    {/* scope dots */}
                    <div className="mt-4 flex gap-2 md:hidden justify-center items-center">
                        {scopesData.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveIndex(index)}
                                className={`rounded-full transition-all duration-300 ${activeIndex === index
                                    ? "w-3 h-3 bg-[#666CE4] border-[#666CE4]" // Active dot: larger and blue
                                    : "w-2 h-2 bg-[#83838a] border-gray-400" // Inactive dot: smaller and white
                                    }`}
                            ></button>
                        ))}
                    </div>
                    <div className='flex md:flex-row flex-col justify-center md:gap-2 cursor-pointer'>
                        <div className='md:w-fit w-full bg-[#7C5CFC] text-white flex items-center justify-center px-8 py-3 mt-4 text-base rounded-md' onClick={() => { navigate('/contact-us'); }}>Contact Us</div>
                        <div className='md:w-fit w-full text-[#3F3F50] flex items-center justify-center px-8 py-3 mt-4 text-base rounded-md border border-[#D1D1DB]' onClick={() => { navigate('/about-us'); }}>Know more about Us </div>
                    </div>
                </div>
                <div className='w-full bg-[#121217] flex flex-col md:flex-row gap-4 justify-between items-center py-10 xl:max-h-[32rem]'>
                    <div className='w-full md:w-[50%] px-6 md:px-20 flex flex-col gap-4'>
                        <div className='text-purpleBtnDark uppercase font-bold text-xl text-[#AB97FC]'>Talk to Us? Get in Touch</div>
                        <div className='font-bold text-3xl sm:text-4xl leading-[60px] text-[#FFFFFF]'>
                            Got an Idea? Let's Make It a Reality!
                        </div>
                        <div className='font-normal text-lg sm:text-xl text-[#90A3BF] text-left'>
                            Now it's your turn to bring your imagination into reality. Join us today!
                        </div>
                        <div className='w-fit bg-purpleDark text-white px-8 py-3 mt-4 text-base rounded-md cursor-pointer'
                            onClick={() => { setShowDialog(true) }}>
                            Tell Your Idea
                        </div>
                    </div>
                    <div className='w-full mt-8 md:mt-0 md:w-[50%] flex justify-center md:justify-start xl:h-[30rem]'>
                        <img src="https://vidattwibsitesvg.s3.us-east-1.amazonaws.com/assets/home/group-people.png" alt='Group of people' className='w-[80%] sm:w-[60%] md:w-[100%] object-contain' />
                    </div>
                </div>
                {/* <div id="we-are-hiring" className="w-full p-6 md:p-14 flex flex-col md:flex-row gap-6 md:gap-8 justify-between items-center bg-[#FFF9EB] -mt-8">
                    <div className="flex flex-col gap-2 text-center md:text-left justify-start items-center md:items-start">
                        <div className="font-bold text-[#0D121F] text-[47px] leading-tight md:leading-[48px]">We are hiring!</div>
                        <div className="font-normal text-[#222222] text-sm md:text-base">
                            We are growing fast and always looking for smart people. If you think you suit to any of the open roles, then join us.
                        </div>
                    </div>
                    <div className="w-fit h-full bg-purpleBtn text-white px-6 py-3 text-sm md:text-base rounded-md cursor-not-allowed text-center">View Open Positions</div>
                </div> */}
            </div>
            {showDialog ?
                <Dialog
                    children={<DemoForm />}
                    header='Get a Demo'
                    subHeader=''
                    onClose={() => setShowDialog(false)}
                />
                : null}
            <Footer />
        </div>
    )
}

export default Home