import React from 'react'
import {ReactComponent as CloseIcon} from '../assets/close-icon.svg'

function Dialog({ header,subHeader, children, accept = false, warning = false, showClose = true, showAction = false, onClose}){
    return(
        <div className='fixed top-0 left-0 bg-white/30 w-full h-full bg-opacity-40 z-50'>
            <div className='relative w-[94vw] mx-[3vw] my-4 md:w-[80vw] md:mx-[10vw] min-h-[40vh] md:my-[10vh] p-5 lg:p-8 xl:p-14 flex-col bg-white shadow-md overflow-y-scroll'>
                <div className='w-full flex flex-row justify-between items-center'>
                    <div className='flex flex-col items-start'>
                        <div className='text-xl md:text-[54px] md:leading-[60px] text font-bold text-[#0D121F]'>{ header ?? ' '}</div>
                    </div>
                    {showClose ? 
                        <CloseIcon className='cursor-pointer' onClick={() => onClose()} />
                    : null }
                </div>
                <div className='border-b-2 border-b-[#7C5CFC] mt-4'></div>
                <div className='w-full h-full p-4 overflow-scroll'>
                    { children }
                </div>
                {showAction ? 
                    <div className='w-full p-2 flex flex-row justify-between items-center'>
                        {/* Add actions here */}
                    </div>
                : null }
            </div>
        </div>
    );
}

export default Dialog