import { React, useState, useEffect } from 'react'
import { ReactComponent as VidattLogo } from '../assets/vidatt-logo-color-less.svg'
import Dialog from './dialog'
import DemoForm from './demoForm'
import { useNavigate } from 'react-router'

import { ReactComponent as Plus } from '../assets/plus-icon.svg'
import { ReactComponent as Minus } from '../assets/minus-icon.svg'
// import { ReactComponent as OpenNew } from '../assets/open-outside.svg'

const Footer = () => {
    const [showDialog, setShowDialog] = useState(false)
    const navigate = useNavigate()
    const [isMobile, setMobile] = useState(false)
    const [openFooter, setOpenFooter] = useState('')

    const handleResize = () => {
        if (window.innerWidth < 750) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        const { innerWidth } = window
        if (innerWidth <= 750) {
            setMobile(true)
        }
    }, [setMobile])

    return (
        <div className='w-full h-full px-10 md:px-[80px] xl:px-[100px] py-10  bg-white flex flex-col gap-4'>
            <div className={`min-h-[300px] pb-4 md:pb-0 flex flex-col md:flex-row gap-10 justify-start ${isMobile ? '' : 'border-b-2 border-b-[#CEBEFE]'}`}>
                <div className='flex flex-col gap-5 md:max-w-[24%]'>
                    <VidattLogo className='w-[112px] h-[30px] text-purpleBtn' />
                    {isMobile ? <div className='text-lg text-[#596780]'>Data visualization, Earth sciences & Vision services for your business.</div>
                        : <div className='text-lg text-[#596780]'>
                            Gen AI, Earth Sciences , AI- Machine Vision, & Full Stack services for
                            your business.
                        </div>}
                    <div className='w-fit bg-purpleBtn text-white px-8 py-3 text-base rounded-full cursor-pointer' onClick={() => { setShowDialog(true) }}>Request for Demo</div>
                </div>
                {isMobile ?
                    <div className='flex flex-col gap-5 min-w-[15%] font-sans'>
                        <div className='w-full p-2 flex flex-row items-center justify-between border-b border-b-[#3F3F50]'>
                            <div className='text-[#1A202C] font-semibold text-xl cursor-pointer' onClick={() => navigate('/about-us')}>About Us</div>
                            {openFooter === 'about-us' ? <Minus onClick={() => { setOpenFooter('') }} /> : <Plus onClick={() => { setOpenFooter('about-us') }} />}
                        </div>
                        {openFooter === 'about-us' && <div className='flex flex-col gap-5 min-w-[15%] font-sans' >
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#why-us')}>Why Us</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#our-vision')}>Our vision</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#clients')}>Clients</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#our-team')}>Team</div>
                            {/* <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#we-are-hiring')}>Careers</div> */}
                        </div>}
                        <div className='w-full p-2 flex flex-row items-center justify-between border-b border-b-[#3F3F50]'>
                            <div className='text-[#1A202C] font-semibold text-xl cursor-pointer' >Products</div>
                            {openFooter === 'product' ? <Minus onClick={() => { setOpenFooter('') }} /> : <Plus onClick={() => { setOpenFooter('product') }} />}
                        </div>
                        {openFooter === 'product' && <div className='flex flex-col gap-5 min-w-[15%] font-sans' >
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/product/proenvAI')}>Proenv.AI</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/product/inspectra-x')}>InspectraX</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/product/co-pilot')}>Copilot</div>
                        </div>}
                        <div className='w-full p-2 flex flex-row items-center justify-between border-b border-b-[#3F3F50]'>
                            <div className='text-[#1A202C] font-semibold text-xl cursor-pointer'>Services</div>
                            {openFooter === 'services' ? <Minus onClick={() => { setOpenFooter('') }} /> : <Plus onClick={() => { setOpenFooter('services') }} />}
                        </div>
                        {openFooter === 'services' && <div className='flex flex-col gap-5 min-w-[15%] font-sans' >
                            <div className='text-base font-normal text-[#596780] cursor-not-allowed' title='coming soon' >Research & Development</div>
                            <div className='text-base font-normal text-[#596780] cursor-not-allowed' title='coming soon' >Scratch to end</div>
                            <div className='text-base font-normal text-[#596780] cursor-not-allowed' title='coming soon' >Product Services</div>
                            <div className='text-base font-normal text-[#596780] cursor-not-allowed' title='coming soon' >Projects</div>
                        </div>}
                        <div className='w-full p-2 flex flex-row items-center justify-between border-b border-b-[#3F3F50]'>
                            <div className='text-[#1A202C] font-semibold text-xl'>Follow Us</div>
                            {openFooter === 'follow' ? <Minus onClick={() => { setOpenFooter('') }} /> : <Plus onClick={() => { setOpenFooter('follow') }} />}
                        </div>
                        {openFooter === 'follow' && <div className='flex flex-col gap-5 min-w-[15%] font-sans' >
                            <a className='text-base font-normal text-[#596780] cursor-pointer' href='https://in.linkedin.com/company/vidatt' rel="noreferrer" target='_blank'>LinkedIn</a>
                        </div>}
                    </div> :
                    <div className='w-full flex flex-row justify-between gap-4 font-sans'>
                        <div className='flex flex-col gap-5 min-w-[25%] font-sans'>
                            <div className='text-[#1A202C] font-semibold text-xl cursor-pointer' onClick={() => navigate('/about-us')}>About Us</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#why-us')}>Why Us</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#our-vision')}>Our vision</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#clients')}>Clients</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#our-team')}>Team</div>
                            {/* <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/about-us#we-are-hiring')}>Careers</div> */}
                        </div>
                        <div className='flex flex-col gap-5 min-w-[25%] font-sans'>
                            <div className='text-[#1A202C] font-semibold text-xl cursor-pointer' >Products</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/product/proenvAI')}>Proenv.AI</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/product/inspectra-x')}>InspectraX</div>
                            <div className='text-base font-normal text-[#596780] cursor-pointer' onClick={() => navigate('/product/co-pilot')}>Copilot</div>
                        </div>
                        <div className='flex flex-col gap-5 min-w-[25%] font-sans'>
                            <div className='text-[#1A202C] font-semibold text-xl cursor-pointer'>Services</div>
                            <div className='text-base font-normal text-[#596780] cursor-not-allowed' title='coming soon' >Research & Development</div>
                            <div className='text-base font-normal text-[#596780] cursor-not-allowed' title='coming soon' >Scratch to end</div>
                            <div className='text-base font-normal text-[#596780] cursor-not-allowed' title='coming soon' >Product Services</div>
                            <div className='text-base font-normal text-[#596780] cursor-not-allowed' title='coming soon' >Projects</div>
                        </div>
                        <div className='flex flex-col gap-5 min-w-[25%] font-sans'>
                            <div className='text-[#1A202C] font-semibold text-xl'>Follow Us</div>
                            <a className='text-base font-normal text-[#596780] cursor-pointer' href='https://in.linkedin.com/company/vidatt' rel="noreferrer" target='_blank'>LinkedIn</a>
                        </div>
                    </div>
                }

            </div>
            <div className={`flex ${isMobile ? 'flex-col border border-[#D1D1DB] rounded-md p-2 bg-[#F7F7F8]' : 'flex-row'} justify-between`}>
                <div className={`flex flex-col md:flex-row gap-4 xl:gap-8 md:divide-x-2 md:items-center font-sans font-semibold text-lg ${isMobile ? 'justify-between ' : 'justify-start '}`}>
                    {/* <div className={`${isMobile ? 'w-full flex flex-row justify-between items-center' : ''}`}>Privacy Policy {isMobile && <OpenNew />}</div>
                    <div className={`md:pl-4 ${isMobile ? 'flex flex-row justify-between items-center' : ''}`}>Terms & Conditions {isMobile && <OpenNew />}</div> */}
                    {/* <div className='md:pl-4'>Cookie Policy</div> */}
                    <div className={`md:pl-4 ${isMobile ? 'flex flex-row justify-between items-center' : ''}`}>All Rights Reserved</div>
                </div>
                <div className={`text-[#596780] ${isMobile ? 'text-left mt-2' : 'text-right'} font-medium font-sans text-base`}>© Vidatt</div>
            </div>
            {showDialog ?
                <Dialog
                    children={<DemoForm />}
                    header='Get a Demo'
                    subHeader=''
                    onClose={() => setShowDialog(false)}
                />
                : null}
        </div>
    )
}
export default Footer