import { React, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { ReactComponent as VidattLogo } from '../assets/vidatt-logo.svg'
import { ReactComponent as ArrowDownIcon } from '../assets/arrow-down-icon.svg'
import { ReactComponent as BurgerMenu } from '../assets/majesticons_menu.svg'
import { ReactComponent as RightArrow } from '../assets/right-arrow-icon.svg'
import { ReactComponent as ComingSoon } from '../assets/coming-soon-badge.svg'
import { ReactComponent as New } from '../assets/new-badge.svg'
import Dialog from './dialog'
import DemoForm from './demoForm'

import { ReactComponent as FireImgComp } from '../assets/home/blazeguard-ai.svg'
import { ReactComponent as InspectraXComp } from '../assets/home/inspectra-x.svg'
import { ReactComponent as CopilotImgComp } from '../assets/home/co-pilot.svg'
import { ReactComponent as SafeIcon } from '../assets/safeIcon.svg'
import { ReactComponent as SeverityImage } from '../assets/severityTextIcon.svg'
import { ReactComponent as DashLine } from '../assets/dash-line.svg'

import { ReactComponent as Movenext } from '../assets/aboutus/movenext.svg'
import { ReactComponent as Moveback } from '../assets/aboutus/moveback.svg'

import { ReactComponent as HSCODEM } from '../assets/hscode-nav-M.svg'
import { ReactComponent as HSCODED } from '../assets/hscode-nav-D.svg'


const Navigation = () => {
  const [showProducts, setProductsShow] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const [isMobile, setMobile] = useState(false)
  const [showMobileProd, setMobileProd] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const handleResize = () => {
    if (window.innerWidth < 800) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    const { innerWidth } = window
    if (innerWidth <= 800) {
      setMobile(true)
    }
  }, [setMobile])

  const productData = [
    {
      name: 'fire',
      imageComp: <FireImgComp />,
      title: 'Proenv.ai- Advanced Environmental AI',
      description: 'Leveraging Cameras, Edge processors & AI-powered image analysis, Our product offers un-paralleled accuracy in identifying nature co existence',
      hoverIcon: <SeverityImage />,
      hoverTxt: 'Allocated to Madhu',
      link: '/product/proenvAI',
      comingSoon: false,
      newProduct: false,
    },
    {
      name: 'compressor',
      imageComp: <InspectraXComp />,
      title: 'InspectraX - Visual defect identification',
      description: 'Reimagine quality control at every step as your gateway to high quality products with very low defects, where most defects are caught instantly early in the production cycle.',
      hoverIcon: <SeverityImage />,
      hoverTxt: 'Elephant found',
      link: '/product/inspectra-x',
      comingSoon: false,
      newProduct: false,
    },
    {
      name: 'copilot',
      imageComp: <CopilotImgComp />,
      title: 'Vidatt Copilot ',
      description: 'Experience cutting-edge solutions engineered to leverage data for optimal business performance with GenAi.',
      hoverIcon: <SafeIcon />,
      hoverTxt: 'Safe from floods',
      link: '/product/co-pilot',
      comingSoon: false,
      newProduct: true,
    },
  ]
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setItemsPerPage(3);
      } else if (window.innerWidth >= 768) {
        setItemsPerPage(2);
      } else {
        setItemsPerPage(1);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = () => {
    if (currentIndex + itemsPerPage < productData.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  const handleBack = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

  const displayedItems = productData.slice(currentIndex, currentIndex + itemsPerPage);
  return (
    <div className={`w-full ${isMobile ? 'flex flex-col justify-center items-center' : ''}`}>
      <div className={`w-full ${isMobile ? 'min-h[50px]' : 'h-[100px]'} sticky z-10 top-0 left-0 px-[5%] py-[24px] flex flex-row items-center justify-between bg-[#000000] border-b border-b-white/15`}>
        <VidattLogo className='w-[112px] h-[30px] cursor-pointer' onClick={() => { navigate('/home') }} />
        {isMobile ?
          <div></div> :
          <div className='flex flex-col items-center gap-4 py-3 md:flex-row lg:gap-6 xl:gap-10'>
            <div className={` text-lg cursor-pointer ${pathname === '/home' ? 'text-[#E7DEFE] font-bold' : 'text-white font-normal'}`} onClick={() => { navigate('/home') }} onMouseEnter={() => { setProductsShow(false) }} >
              Home
              {(pathname === '/home' || pathname === "/") && (
                <div className="">
                  <DashLine className="text-[#DAD1F0]" />
                </div>
              )}
            </div>
            <div className={` text-lg cursor-pointer ${pathname === '/about-us' ? 'text-[#E7DEFE] font-bold  saturate-200 brightness-110' : 'text-white font-normal'}`} onClick={() => { navigate('/about-us') }} onMouseEnter={() => { setProductsShow(false) }} >About Us   {pathname === '/about-us' && (
              <div className="">
                <DashLine className="text-[#DAD1F0]" />
              </div>
            )}</div>
            <div className={` flex flex-row items-center gap-2  text-lg ${pathname.includes('/product') ? 'text-[#E7DEFE] font-bold' : 'text-white font-normal'} cursor-pointer`} onMouseEnter={() => { setProductsShow(true) }} onClick={() => { navigate('/products'); setProductsShow(false) }} >
              <div className='flex flex-col'>
                <div className='flex flex-row gap-1 items-center'>
                  Products  <ArrowDownIcon className={`w-4 h-4 mt-[5px] ${pathname.includes('/product') ? 'text-[#E7DEFE] font-bold' : 'text-white font-normal'}`} />
                </div>
                <div className='flex'>
                  {pathname === '/products' && (
                    <div className="">
                      <DashLine className="text-[#DAD1F0]" />
                    </div>
                  )}
                </div>
              </div>
              {showProducts && (
                <div
                  className="absolute top-20 left-0 z-[1000] mx-[4vw] w-full max-w-full sm:max-w-[90vw] flex flex-col gap-2 divide-y bg-white border-b divide-white/15 border border-white/15 rounded-lg shadow-lg"
                  onMouseOver={() => { setProductsShow(true) }}
                  onMouseLeave={() => { setProductsShow(false) }}
                >
                  <div
                    className="flex flex-col w-full gap-4 px-4 mx-auto my-6 overflow-hidden sm:flex-row"
                    onMouseOver={() => { setProductsShow(true) }}
                    onMouseLeave={() => { setProductsShow(false) }}
                  >
                    {displayedItems.map((item) => (
                      <div className="z-[1000] w-full h-full flex flex-col sm:gap-4" key={item.name}>
                        {item.imageComp}
                        <div className="flex flex-row items-center gap-4 text-2xl font-bold text-left">
                          <div className="text-[#040815]">{item.title} </div>
                          {item.comingSoon && <ComingSoon className="inline-block" />}
                          {item.newProduct && <New />}
                        </div>
                        <div className="max-w-[350px] text-base text-[#596780] text-left ">{item.description}</div>
                        <div
                          className={`mt-auto text-base font-semibold text-left text-purpleBtn flex flex-row gap-1 items-center ${item.comingSoon ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(item.link);
                          }}
                        >
                          Know more<RightArrow className="w-4 h-3" />
                        </div>

                      </div>
                    ))}
                  </div>
                  <div className="flex justify-center mt-4">

                    <button
                      onClick={handleBack}
                      disabled={currentIndex === 0}
                      className="text-blue-500 cursor-pointer disabled:opacity-50"
                    >
                      <Moveback />
                    </button>
                    <button
                      onClick={handleNext}
                      disabled={currentIndex + itemsPerPage >= productData.length}
                      className="text-blue-500 cursor-pointer disabled:opacity-50"
                    >
                      <Movenext />
                    </button>

                  </div>
                  <HSCODED className='w-[96%] mx-auto my-4' onClick={(e) => {
                    e.stopPropagation();
                    navigate('/product/hscode')
                  }} />

                </div>
              )}

            </div>

            <div className={` text-lg cursor-pointer ${pathname === '/services' ? 'text-[#E7DEFE] font-bold' : 'text-white font-normal'}`} onClick={() => { navigate('/services') }} onMouseEnter={() => { setProductsShow(false) }} >
              Services
              {pathname === '/services' && (
                <div className="">
                  <DashLine className="text-[#DAD1F0]" />
                </div>
              )}
              {/* <div className='absolute left-0 invisible w-32 p-2 text-base text-white border rounded-md group-hover:visible top-8 bg-homeBg border-purpleBtn'>Coming Soon</div> */}
            </div>
            <div className={` text-lg cursor-pointer ${pathname === '/contact-us' ? 'text-[#E7DEFE] font-bold' : 'text-white font-normal'}`} onClick={() => { navigate('/contact-us') }} onMouseEnter={() => { setProductsShow(false) }} >Contact Us {pathname === '/contact-us' && (
              <div className="">
                <DashLine className="text-[#DAD1F0]" />
              </div>
            )}</div>
          </div>}
        {
          isMobile ?
            <div className='flex flex-row items-center justify-end'>
              <div className='min-w-[125px] px-3 py-2 text-white text-base font-sans font-semibold bg-purpleBtn rounded-full cursor-pointer' onClick={() => { setShowDialog(true) }} >Get a Demo</div>
              <BurgerMenu className='w-14 h-14 text-[#E7DEFE]' onClick={() => { setMobileProd(!showMobileProd) }} />
            </div>
            : <div className='min-w-[125px] px-3 py-2 text-white text-base flex items-center justify-center  font-sans font-semibold bg-purpleBtn rounded-full cursor-pointer' onClick={() => { setShowDialog(true) }} onMouseEnter={() => { setProductsShow(false) }} >Get a Demo</div>
        }
      </div>
      {showMobileProd ?
        <div className='w-[100%] min-h-screen flex flex-col justify-center bg-homeBg border-b border-b-white/15'>
          <div className='flex flex-col items-center gap-4 py-3 md:flex-row lg:gap-6 xl:gap-10'>
            <div className={` text-lg cursor-pointer ${(pathname === '/home' || pathname === "/") ? 'text-[#E7DEFE] font-bold' : 'text-white font-normal'}`} onClick={() => { navigate('/home') }}  >Home {(pathname === '/home' || pathname === "/") && (
              <div className="">
                <DashLine className="text-[#DAD1F0]" />
              </div>
            )}</div>
            <div className={`font-normal text-lg cursor-pointer ${pathname === '/about-us' ? 'text-[#E7DEFE]' : 'text-white'}`} onClick={() => { navigate('/about-us') }}>About Us {pathname === '/about-us' && (
              <div className="">
                <DashLine className="text-[#DAD1F0]" />
              </div>
            )}</div>
            <div className={`relative flex flex-row items-center gap-2 font-normal text-lg ${pathname.includes('/product') ? 'text-[#E7DEFE]' : 'text-white'} cursor-pointer`} onClick={() => { setProductsShow(!showProducts) }}>
              Products <ArrowDownIcon className={`w-4 h-4 ${pathname.includes('/product') ? 'text-[#E7DEFE]' : 'text-white'}`} />
            </div>
            {showProducts &&
              <div className='w-[85%] mr-[3%] p-3 flex flex-col justify-start divide-y divide-[#55556D] gap-2 bg-[#161D2E]' onMouseOver={() => { setProductsShow(true) }} onMouseLeave={() => { setProductsShow(false) }}>
                <div className='flex flex-col justify-start gap-3 px-4 pt-4 pb-2'>
                  <div className='flex flex-row justify-between'>
                    <div className='text-xl font-bold text-white'>Copilot AI</div>
                  </div>
                  <div className='text-[#B5C2DB] text-xs'>
                    Step into the next era of technological innovation with Gen AI, a trailblazing solution designed to transform how businesses harness the power of data
                  </div>
                  <div className='flex flex-row items-center gap-2 py-3 text-[#E7DEFE]' onClick={() => { navigate('/product/co-pilot') }}>
                    <div className='text-xs font-semibold text-[#7C5CFC]'>Know more</div>
                    <RightArrow className='w-3' />
                  </div>
                </div>
                <div className='flex flex-col justify-start gap-3 px-4 pt-4 pb-2'>
                  <div className='flex flex-row justify-between'>
                    <div className='text-xl font-bold text-white'>Inspectra X</div>
                  </div>
                  <div className='text-[#B5C2DB] text-xs'>
                    ReImagine inspections as your gateway to flawless production. Where every defect is caught instantly, and precision is effortless.
                    Elevate your inspection game with the power of AI vision
                  </div>
                  <div className='flex flex-row items-center gap-2 py-3 text-[#E7DEFE] ' onClick={() => { navigate('/product/inspectra-x') }}>
                    <div className='text-xs font-semibold text-[#7C5CFC]'>Know more</div>
                    <RightArrow className='w-3' />
                  </div>
                </div>
                <div className='flex flex-col justify-start gap-3 px-4 pt-4 pb-2'>
                  <div className='flex flex-row justify-between'>
                    <div className='text-xl font-bold text-white'>Proenv.ai</div>
                  </div>
                  <div className='text-[#B5C2DB] text-xs'>
                    Leveraging Cameras, Edge processors & AI-powered image analysis, Our product offers un-paralleled accuracy in identifying nature co existence
                  </div>
                  <div className='flex flex-row items-center gap-2 py-3 text-[#E7DEFE]' onClick={() => { navigate('/product/proenvAI') }}>
                    <div className='text-xs font-semibold text-[#7C5CFC]'>Know more</div>
                    <RightArrow className='w-3' />
                  </div>
                  <HSCODEM className='min-w-full my-4' onClick={(e) => {

                    e.stopPropagation();
                    navigate('/product/hscode')
                  }} />

                  <div className={`font-normal text-md  border-[#363F5E] rounded-full flex justify-center items-center bg-[#FFFFFF] text-[#242C3D] px-6 py-3 mt-4 w-36 h-12 mx-auto `}
                    onClick={() => { navigate('/products'); }}
                  >
                    Our Products
                  </div>
                </div>
              </div>
            }
            <div className={`font-normal text-lg ${pathname === '/services' ? 'text-[#E7DEFE]' : 'text-white'}`} onClick={() => { navigate('/services') }} >
              Services{pathname === '/services' && (
                <div className="">
                  <DashLine className="text-[#DAD1F0]" />
                </div>
              )}
            </div>
            <div className={`font-normal text-lg ${pathname === '/contact-us' ? 'text-[#E7DEFE]' : 'text-white'}`} onClick={() => { navigate('/contact-us') }}>Contact Us {pathname === '/contact-us' && (
              <div className="">
                <DashLine className="text-[#DAD1F0]" />
              </div>
            )}</div>
            <div className={`font-normal text-md rounded-full bg-[#7C5CFC]  px-6 py-3 mt-2 mb-4 ${pathname === '/contact-us' ? 'text-[#E7DEFE]' : 'text-white'}`} onClick={() => setShowDialog(true)}>
              Get A Demo
            </div>
          </div>
        </div>
        : null}

      {
        showDialog ?
          <Dialog
            children={<DemoForm />}
            header='Get a Demo'
            subHeader=''
            onClose={() => setShowDialog(false)}
          />
          : null
      }
    </div >
  )
}

export default Navigation